import Global from '@/layout/Global';

const routes = [
  {
    path: '/',
    component: Global,

    routes: [
      {
        path: '/',
        exact: true,
        redirect: '/faq',
      },
      {
        path: '/faq',
        component: '/Faq',
        title: 'Faq',
        meta: {
          title: 'Faq',
        },
      },
      {
        path: '/policy',
        component: '/Policy',
        title: 'Policy',
      },
      {
        path: '/delUserData',
        component: '/DelUserData',
        title: 'DelUserData',
      },
      {
        path: '/xc',
        exact: true,
        redirect: '/xc/guides',
      },
      {
        path: '/xc/guides',
        component: '/XCGuide',
        title: '家族指南',
      },
      {
        path: '/xc/guide/:id',
        component: '/XCGuide/Article',
        title: '家族指南',
      },
      {
        path: '/xc/join',
        component: '/XCAdLink',
        title: '《新信長之野望》加入送稀有名將！',
      },
      {
        // path: '*',
        component: '/page404',
        title: '404',
      },
    ],
  },

  {
    // path: '*',
    component: '/page404',
    title: '404',
  },
];

export default routes;
